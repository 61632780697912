<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Empresa</h1>
		</div>
		<br />
		<div v-if="verificar_parametro" class="row form-group">
			<div class="col-sm-12 text-center text-danger">
				<strong>Atención</strong>: Es necesario completar la información de la empresa para que puedas comenzar a subir facturas
			</div>
		</div>
		<div class="row form-group">
			<label for="" class="col-form-label col-sm-2">Empresa</label>
			<div class="col-sm-10">
				{{ empresa.empresa }}
			</div>
		</div>
		<div class="row form-group">
			<label for="empresa" class="col-form-label col-sm-2">Descripción</label>
			<div class="col-sm-10">
				{{ empresa.descripcion }}
			</div>
		</div>
		<div class="row form-group">
			<label for="titular_cuenta" class="col-form-label col-sm-2">Titular de la cuenta</label>
			<div class="col-sm-10"><input v-model="empresa.titular_cuenta" type="text" name="titular_cuenta" id="titular_cuenta" class="form-control" autocomplete="off"></div>
		</div>
		<div class="row form-group">
			<label for="banco" class="col-form-label col-sm-2">Banco</label>
			<div class="col-sm-10">
				<select v-model="empresa.banco" name="banco" id="banco" class="form-control">
					<option v-for="opcion in catalogo_bancos.valores" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
				</select>
			</div>
		</div>
		<!-- <div class="row form-group">
			<label for="cuenta" class="col-form-label col-sm-2">Número de cuenta</label>
			<div class="col-sm-10"><input v-model="empresa.cuenta" type="text" name="cuenta" id="cuenta" class="form-control" autocomplete="off"></div>
		</div> -->
		<div class="row form-group">
			<label for="clabe_interbancaria" class="col-form-label col-sm-2">Clabe interbancaria</label>
			<div class="col-sm-10"><input v-model="empresa.clabe_interbancaria" type="text" name="clabe_interbancaria" id="clabe_interbancaria" class="form-control" autocomplete="off"></div>
		</div>
		<div class="row form-group">
			<label for="nombre_contacto" class="col-form-label col-sm-2">Nombre de contacto</label>
			<div class="col-sm-10"><input v-model="empresa.nombre_contacto" type="text" name="nombre_contacto" id="nombre_contacto" class="form-control" autocomplete="off"></div>
		</div>
		<div class="row form-group">
			<label for="email_contacto" class="col-form-label col-sm-2">Email de contacto</label>
			<div class="col-sm-10"><input v-model="empresa.email_contacto" type="email" name="email_contacto" id="email_contacto" class="form-control" autocomplete="off"></div>
		</div>
		<div class="row form-group">
			<div class="col-sm-2 offset-sm-10 text-right">
				<button class="btn complementary-btn" @click="guardar_cambios">Guardar</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import api from '@/apps/tesoreria/api/proveedores';
	import apiCatalogo from '@/apps/tesoreria/api/catalogos';

	export default {
		data: () => ({
			empresa: {
				empresa: null
				,descripcion: null
				,titular_cuenta: null
				,cuenta: null
				,clabe_interbancaria: null
				,nombre_contacto: null
				,email_contacto: null
			}
			,catalogo_bancos:[]
		})
		,mounted: function() {
			this.cargar_empresa();
			this.cargar_catalogo();
		}
		,methods: {
			cargar_empresa: function() {
				api.cargar_empresa()
				.then(res => {
					this.$log.info('res', res);
					this.empresa = res.data;
				})
				.catch(err => {
					this.$log.error('err', err);
					this.$helper.showAxiosError(err,'Error');
				})
			}
			,cargar_catalogo: async function() {
				try {
					this.catalogo_bancos = (await apiCatalogo.buscar_catalogo('bancos')).data;
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,guardar_cambios: function() {
				api.guardar_empresa(this.empresa)
				.then(res => {
					this.$log.info('res', res);

					this.$notify({
						group: 'notifications'
						,type: 'success'
						,title: 'Se guardo la información con exito'
						// ,text: errorMessage
						,duration: 9000
					});
				})
				.catch(err => {
					this.$log.error('err', err)
					this.$helper.showAxiosError(err,'Error');
				})
			}
		}
		,computed: {
			verificar_parametro: function() {
				this.$log.info('valida: ',this.$route.params.valida);
				return this.$route.params.valida == 0;
			}
		}
	}
</script>